import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {Link} from "react-router-dom";


function TokenChip(props){


    return(
        
        <Button style={{marginLeft: "8px" , marginTop: "8px", display: "inline-block", padding: "0 25px", height: "32px", fontSize: "16px", lineHeight: "32px", borderRadius: "25px", backgroundColor: "#16213e", color: "white", float: "left"}}>
            <img style={{backgroundColor: "#1a1a2e", float: "left", margin: "4px 10px 0 -25px", height: "24px", width: "24px", border: "solid 1px #2a2d41", borderRadius: "50%"}} src={props.tokenList[props.token].logo}  width="96" height="96"></img>
            <span>{props.tokenList[props.token].symbol}</span>
            
        </Button> 
       
        
    );
}

export default TokenChip;