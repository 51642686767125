import gasTokenLogo from './img/rose.png';
import dexTokenLogo from './img/TokenLogo.png';

function OfficialTokens(){

    return(
        {
            'gasToken': {
                'logo': gasTokenLogo,
                'name': 'Oasis Network',
                'symbol': 'ROSE',
                'address': 'N/A'

            },
            'dexToken': {
                'logo': dexTokenLogo,
                'name': 'StealthySwap',
                'symbol': 'STLTH',
                'address': '0x0000000000000000000000000'
            }
        }
    )
}

export default OfficialTokens;