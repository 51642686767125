import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Alert, Grid, Button, Typography, Icon, Divider, Paper, Card, Box, Input, TextField} from '@mui/material';
import TokenChip from "../TokenChip";
import ethlogo from "../img/eth.png";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import OfficialTokens from '../OfficialTokens';
const Add = (props) => {
    const [selectedPair, setSelectedPair] = useState(["gasToken", "dexToken"]);
    const [error, setError] = useState("");
    const [amounts, setAmounts] = useState(["", ""]);
    

    const handleAmountIn = (event) => {
        setAmounts([event.target.value, amounts[1]]);
    }

    const handleAmountOut = (event) => {
        setAmounts([amounts[0], event.target.value]);
    }


    var tokenList = OfficialTokens();


    return(
    <Grid container justifyContent="center">
        
        <Grid overflow="hidden" borderRadius="25px" marginTop="10%" item xs={10} sm={6} md={4} xl={3}>
            <Card sx={{paddingBottom: "16px"}} >
                
                <Grid container>
                    <Grid item xs={3} marginTop={"16px"}>
                       <Link to="/pool/" style={{textDecoration: "none"}}><ArrowBackIcon style={{color: "white"}}></ArrowBackIcon></Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight={"bold"} marginTop={2} marginLeft={"24px"} align='center' color="white">Add Liquidity</Typography>
                    </Grid>
                    <Grid item xs={3} marginTop={"8px"}>
                        <Button sx={{minWidth: "1px", width: "40px"}}>

                        
                        <svg  width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="white" aria-hidden="true" className="w-[26px] h-[26px] transform rotate-90 hover:text-white"><path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                        </Button>
                    </Grid>
                </Grid>
                
                <Box sx={{backgroundColor: "#1a1a2e", marginLeft: "24px", marginTop: "24px", marginRight: "24px", marginBottom: "0px", border: "solid 1px #2a2d41", borderRadius: "8px"}}>
                <Grid container justifyContent="left">
                        <Grid item xs={12}>
                            <TokenChip tokenList={tokenList} token={selectedPair[0]} ></TokenChip>
                        </Grid>
                        
                        <Grid paddingTop="16px" paddingLeft={2} paddingBottom={2} item xs={6}>
                            <TextField onChange={(e) => handleAmountIn(e)}  variant='standard' placeholder={"0"} value={amounts[0] != 0 ? amounts[0] : ""} display="block"  sx={{input: {color: "white", textAlign: "left", fontSize: 20} }}></TextField>
                        </Grid>
                </Grid>

                </Box>
                <AddIcon style={{color: "white"}}></AddIcon>
                <Box sx={{backgroundColor: "#1a1a2e", marginTop: "0px", marginLeft: "24px", marginRight:"24px", border: "solid 1px #2a2d41", borderRadius: "8px"}}>
                    <Grid container justifyContent="left">
                        <Grid item xs={12}>
                            <TokenChip tokenList={tokenList} token={selectedPair[1]} ></TokenChip>
                        </Grid>
                        
                        <Grid paddingTop="16px" paddingLeft={2} paddingBottom={2} item xs={6}>
                            <TextField onChange={(e) => handleAmountOut(e)}  variant='standard' display="block" placeholder={"0"}  value={amounts[1] != 0 ? amounts[1] : ""} sx={{input: {color: "white", textAlign: "left", fontSize: 20} }}></TextField>
                        </Grid>
                    </Grid>
                    

                   
                    

                </Box>
                {error != "" &&
                <Box padding={'24px'}>
                    <Alert variant='outlined' severity='error'>{error}</Alert>
                </Box>
                }
                <br></br>
                <br></br>
                {props.connected == false && 
                <Button style={{textTransform: "none"}} variant='contained' onClick={()=>{props.connectSigner()}}>Connect Wallet</Button>
                }
                {String(amounts) == "," && props.connected &&
                    <Button  disabled style={{textTransform: "none", background : "#1a1a2e", color: "gray"}} variant='contained'>Enter an amount</Button>
                }
                {String(amounts) != "," && props.connected &&
                <div>   
                    <Button style={{textTransform: "none"}} variant='contained'>Add</Button>
                    
                </div>
                    
                    
                }

                <div style={{height: "24px"}}></div>
            </Card>
            
            
        </Grid>
    </Grid>
    
    
    );
}

export default Add;