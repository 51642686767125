import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const themeOptions = {
  palette: {
    type: 'dark',
    typography: {
      allVariants: {
        color: 'white'
      },
      button: {
        textTransform: 'none'
      },
    },
    primary: {
      main: '#5893df',
    },
    secondary: {
      main: '#2ec5d3',
    },
    background: {
      default: '#1a1a2e',
      paper: '#16213e',
    },
  },
  
};

const theme=createTheme(themeOptions);

root.render(
  <BrowserRouter>

      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App />
      </ThemeProvider>

  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
