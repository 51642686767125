import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {Link} from "react-router-dom";
import SvgIcon from '@mui/material/SvgIcon';
import logo from './img/logowhite.png';
import swap from './pages/Swap';
import pool from './pages/Pool';
import mmfox from './img/mmfox.png';
import CheckIcon from '@mui/icons-material/Check';
import {ethers} from "ethers";

const pages = ['swap', 'pool', 'charts'];

function Header(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [ethBalance, setEthBalance] = React.useState("");

  React.useEffect(()=>{
    getEthBalance();
  })

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function getEthBalance(){
    var unformatted = await props.signer.getBalance();
    var split = String(ethers.utils.formatEther(unformatted)).split(".");
    var _balance = split [0] + "." + split[1].slice(0,2);
    console.log("BALANCE: ", _balance);
    setEthBalance(_balance)
  }



  return (
    <AppBar position="sticky" sx={{backgroundColor: 'rgba(22, 33, 62, 0.2)', backdropFilter: "blur(10px)"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Box display={{xs: 'none', md: 'flex'}}>
            <img src={logo} style={{ maxWidth: '40px', mr: 1 }} />
            </Box>
          
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              fontSize: 12,
              color: 'inherit',
              textDecoration: 'none',
              marginLeft: '12px',
            }}
          >
            StealthySwap
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                backgroundColor: "rgba(26, 26, 46, 0.4)",
                backgroundFilter: "blur(5px)"
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page + "menu"} onClick={handleCloseNavMenu}>
                  <Link key={page + "menuLink"} to={'/'+ page + '/'}><Typography color={"white"} textAlign="center">{page}</Typography></Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box display={{xs: 'flex', md: 'none'}} mr={1}>
          <img src={logo} style={{ maxWidth: '32px', display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              fontSize: 12,
            }}
          >
            StealthySwap
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
                <Link key={page + "3892"} style={{textDecoration: "none"}} to={'/' + page + '/'}>
              <Button 
                textDecoration="none"
                
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
              </Link>
            ))}
          </Box>
        {props.connected == false &&
          <Box sx={{ flexGrow: 0 }}>
            <Button onClick={()=>{props.connectSigner()}} variant='contained' style={{borderRadius: "50px", maxHeight: "32px" ,background: "linear-gradient(90deg, rgba(205, 110, 205, 1) 0%, rgba(102, 0, 185, 1) 100%)", fontSize: 10, fontWeight: "bold"}}>Connect Wallet</Button>
          </Box>
        }
        {props.connected &&
            <div>
                <Typography display="inline">{ethBalance} ROSE </Typography>
                <Typography marginLeft={2} marginRight={2} display="inline" color="gray">{props.userAddress.slice(0, 4) + "..." + props.userAddress.slice(-4)}</Typography>
                
            </div>

        }
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
