import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Alert, Grid, Button, Typography, Icon, Divider, Paper, Card, Box, Input, TextField} from '@mui/material';
import TokenChip from "../TokenChip";
import ethlogo from "../img/eth.png";

import OfficialTokens from '../OfficialTokens';
import LiquidityPreview from '../LiquidityPreview';
const Swap = () => {
    const [selectedPair, setSelectedPair] = useState(["gasToken", "dexToken"]);
    const [error, setError] = useState("");
    const [amounts, setAmounts] = useState([0.00, 0.00]);


    const handleAmountIn = (event) => {
        setAmounts([event.target.value, amounts[1]]);
    }

    const handleAmountOut = (event) => {
        setAmounts([amounts[0], event.target.value]);
    }


    var tokenList = OfficialTokens();


    return(
    <div>
    <Grid container justifyContent="center">
        
        <Grid container item marginTop="10%" xs={10} sm={6} md={4} xl={3} justifyContent="left">
                <Grid item xs={8}>
                    <Typography margin={2} align='left' color="white">Position Overview</Typography>
                </Grid>
                <Grid item xs={4} marginTop={1}>
                    <Link to="/add/" style={{textDecoration: "none"}}><Button style={{minWidth: "1px", borderRadius: "50px", textTransform: "none", fontSize: 12}} variant='contained'>+ New Position</Button></Link>
                </Grid>

            </Grid>
    </Grid>
    <Grid container justifyContent="center">
        <Grid overflow="hidden" borderRadius="25px"  item xs={10} sm={6} md={4} xl={3}>

           
            
                

        <Box sx={{backgroundColor: "#1a1a2e", marginTop: "0px", marginLeft: "24px", marginRight:"24px", border: "solid 1px #2a2d41", borderRadius: "8px"}}>
                <LiquidityPreview></LiquidityPreview>
                <Divider sx={{bgcolor: "#2a2d41", marginLeft: "5%", marginRight: "5%"}}></Divider>
                <LiquidityPreview></LiquidityPreview>
                <Divider sx={{bgcolor: "#2a2d41", marginLeft: "5%", marginRight: "5%"}}></Divider>
                <LiquidityPreview></LiquidityPreview>
                </Box>

                
                
                
                <br></br>
                <br></br>
                
                <div style={{height: "48px"}}></div>
            
            
            
        </Grid>
    </Grid>
    </div>
    
    );
}

export default Swap;