import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Alert, Grid, Button, Typography, Icon, Divider, Paper, Card, Box, Input, TextField} from '@mui/material';
import TokenChip from "./TokenChip";
import ethlogo from "./img/eth.png";
import unknown from './img/Unknown.png';
import rose from './img/rose.png';

import OfficialTokens from './OfficialTokens';

const LiquidityPreview = () => {


    return(
        <Grid container marginBottom={1}>
            <Grid item xs={12} marginLeft={1} marginRight={1} marginTop={1}>
            
            <Button style={{align: "left"}} fullWidth>
                

                    <img src={rose} style={{backgroundColor: "#1a1a2e", float: "left", margin: "4px 10px 0 4px", height: "24px", width: "24px", border: "solid 1px #2a2d41", borderRadius: "50%"}}></img>
                    <img src={unknown} style={{backgroundColor: "#1a1a2e", float: "left", margin: "4px 10px 0 -18px", height: "24px", width: "24px", border: "solid 1px #2a2d41", borderRadius: "50%"}}></img>
                    <Typography color={"white"} marginTop={1} align='left'>ROSE-EXMPL</Typography>

               
            </Button>
            
            </Grid>
            
        </Grid>
    );
}

export default LiquidityPreview;