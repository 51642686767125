
import {Grid, Button, Typography, Icon, Divider, Paper, Card, Box} from '@mui/material';
function Footer(){

    return(
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} lg={8} xl={6}>
                <Typography fontWeight={"bold"} color="white" align='left'>StealthySwap</Typography>
                
            </Grid>
            <div style={{height: '400px'}}></div>
            
        </Grid>
        
    );
}

export default Footer;